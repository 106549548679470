import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect } from 'react';
import getXcolumnJson from "./xcolumn/list";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "@/pages/comp/basic/student/list/area/search/search_area";
import SearchReportArea from "./area/search_area";
import { useReactToPrint } from 'react-to-print';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import AddAttendPopup from "@/pages/comp/edu/attend/popup/add_attend";
import WriteTdAndStudyPopup from "./popup/write_td_and_study";
import ViewTodoDaySortPopup from "@/pages/comp/plan/calendar/area/popup/view_todo_day_sort";
import ViewStuLayerPopup from "@/pages/comp/basic/student/popup/view_layer";
import SendSMSPopup from "@/pages/comp/basic/sms/popup/send_sms";
import FindStudyTitlePopup from "@/pages/comp/plan/study/study/popup/find_study_title";
import LoadingComponent from "@/pcomponents/common/content/loading";
import TodoInfoArrFunc from "@/pcomponents/common/content/todo/list/info_arr_func";
import DailyDataFunc from "@/pages/comp/edu/daily_report/card_list/func/daily_data_func";
import { useNavigate } from 'react-router-dom';
import ListTabArea from "./area/list_tab";
import SearchSubArea from "./area/search_sub";
import CardListArea from "./area/card_list";
import DailyListArea from "./area/daily_list";

function DailyReportCardListPage() {
  const navigate = useNavigate();
  const [list_tab, set_list_tab] = useState(
    strFunc.get_storage("daily_report_list_tab", "") == "" ? "week" :
      strFunc.get_storage("daily_report_list_tab", "")
  );//week,day
  const [tab_id, set_tab_id] = useState("lecture");
  const xColumnArr = getXcolumnJson();
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_lecture_time: "1",
    s_addon_link_user: "1",
    s_addon_daily_report_last: "1",
    s_addon_study_det_by_daily_last: "1",
    //s_addon_daily_report_last_writer_seq: user.user_seq,
    s_addon_daily_report: "1",
    //s_addon_daily_report_writer_seq: user.user_seq,
    s_addon_daily_report_start_date: now_date_str,
    s_addon_daily_report_end_date: now_date_str,
    s_addon_todo: "1",
    s_addon_todo_start_date: now_date_str,
    s_addon_todo_end_date: now_date_str,
    s_addon_todo_s_todo_sort: "",
    s_addon_todo_elapse_time: "1",
    s_addon_todo_s_state: "",
    s_addon_todo_addon_par: "",
    s_addon_home_work_file: "1",
    s_addon_home_work_file_start_date: now_date_str,
    s_addon_home_work_file_end_date: now_date_str,
    s_addon_plan_study_file: "1",
    s_addon_plan_study_file_start_date: now_date_str,
    s_addon_plan_study_file_end_date: now_date_str,
    s_addon_attend: "1",
    s_addon_attend_start_date: now_date_str,
    s_addon_attend_end_date: now_date_str,
    s_attend_sort_detail: "",
    s_attend_start_date: now_date_str,
    s_attend_end_date: now_date_str,
    // s_addon_desk_use: "1",
    // s_addon_desk_use_start_date: now_date_str,
    // s_addon_desk_use_end_date: now_date_str,
    s_addon_month_prescribe: "1",
    s_addon_month_prescribe_start_date: now_date_str,
    s_addon_month_prescribe_end_date: now_date_str,
    s_addon_month_prescribe_writer_seq: "",
    s_addon_month_prescribe_comment: "1",
    s_addon_month_prescribe_counsel_todo: "1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);
  const [sms_phone_check, set_sms_phone_check] = useState("student");//student,parent,both
  const [is_show_etc_group, set_is_show_etc_group] = useState(
    strFunc.get_storage("daily_report_is_show_etc_group", "") == "1" ? true : false
  );
  //strFunc.get_storage("daily_report_is_print_mode", "") == "1" ? true : false
  const [is_print_mode, set_is_print_mode] = useState(false);
  const [show_group_time, set_group_time] = useState({
    sort: strFunc.get_storage("change_group_time_sort", ""),//"":전체,now::현재~,select:선택
    select_time_arr: [],//[13:00,14:00,..]
  });
  const able_time_arr_ref = useRef<any>([]);//[13:00,14:00,..]
  const [refresh, set_refresh] = useState(false);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장 되었습니다.",
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",//add_attend,write_td
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    user_info_arr: [],

    //todo
    select_stu_name: "",
    select_stu_seq: "",
    select_date: "",

    write_td_data: {
      daily_report: {},
      td_sort: "textbook",//textbook,subtextbook
    },

    write_page_data: {
      is_show: false,
      is_update: false,
      is_view_mode: true,
      is_view_basic: false,
      info_arr: [],
      select_idx: 0,
      default_write_info: {},
      tab_id: "monthly_prescribe",
      is_first_tab_scroll: true,
    },

    receive_arr: [],
    row_num: "",
    key_str: "",
    par_info: null,

    title: "출석",
    width: "800px",
    height: "100%",
    y: 0,
  });

  const time_table_wrap_ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => time_table_wrap_ref.current,
  });

  useEffect(() => {
    list({});
    expand_print_div_css();
  }, []);

  const expand_print_div_css = () => {
    if (time_table_wrap_ref.current) {
      // time_table_wrap_ref.current.style.transform="scale(1.15)";
      // time_table_wrap_ref.current.style.marginTop="100px";
    }
  };

  const list = (inOptObj: any) => {
    let tmp_listOpt: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    let tmp_list_form_json = { ...tmp_listOpt };
    let tmp_select_date = tmp_listOpt.s_addon_daily_report_start_date;
    tmp_list_form_json["s_addon_todo_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_todo_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_home_work_file_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_home_work_file_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_plan_study_file_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_plan_study_file_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_attend_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_attend_end_date"] = tmp_select_date;
    tmp_list_form_json["s_attend_start_date"] = tmp_select_date;
    tmp_list_form_json["s_attend_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_desk_use_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_desk_use_end_date"] = tmp_select_date;

    //하루전꺼부터 조회
    let tmp_start_date = tmp_list_form_json["s_addon_todo_start_date"];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
    let pre_date_change_num = -1;
    if (tmp_start_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");
    tmp_list_form_json["s_addon_todo_start_date"] = pre_date_str;
    tmp_list_form_json["s_addon_home_work_file_start_date"] = pre_date_str;
    //일일보고서 일주일 조회
    let week_date_change_num = -tmp_start_date_json.day;
    let week_date_change_end_num = 6 - tmp_start_date_json.day;
    let week_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_num), "Y-m-d");
    let week_date_end_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_end_num), "Y-m-d");
    tmp_list_form_json["s_addon_month_prescribe_start_date"] = week_date_str;
    tmp_list_form_json["s_addon_month_prescribe_end_date"] = week_date_end_str;
    if (list_tab == "week") {
      tmp_list_form_json["s_addon_daily_report_start_date"] = week_date_str;
      tmp_list_form_json["s_addon_daily_report_end_date"] = week_date_end_str;
    } else if (list_tab == "day") {
      tmp_list_form_json["s_addon_daily_report_start_date"] = tmp_select_date;
      tmp_list_form_json["s_addon_daily_report_end_date"] = tmp_select_date;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"] = TodoInfoArrFunc.get_info_arr_by_filter({
            info_arr: response.data["data"]["info_arr"],
            list_opt: tmp_list_form_json,
            base_date_str: tmp_select_date,
            pre_date_str: pre_date_str,
          });
          response.data["data"]["info_arr"] = DailyDataFunc.get_stu_list_of_setting_default_daily_info_arr({
            info_arr: response.data["data"]["info_arr"],
            select_date: tmp_start_date,
            tab_id: tab_id,
          });

          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          set_refresh(!refresh);
        } else {

        }
      });
  };

  const on_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_pri_val_arr: any = [];
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let row_pri_val = info["a_seq"];
        tmp_pri_val_arr.push(row_pri_val);
      }
      set_check_row_val_arr(tmp_pri_val_arr);
    } else {
      set_check_row_val_arr([]);
    }
  };

  const get_select_daily_report_arr = (inData: any) => {
    let opt_obj = {
      is_except_new: false,
      select_date_str: listOpt.s_addon_daily_report_start_date,
      ...inData
    };
    let select_info_arr: any = [];

    for (let i = 0; i < infoArr.length; i++) {
      let info: any = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        if (info.daily_report_arr) {
          for (let j = 0; j < info.daily_report_arr.length; j++) {
            let row_daily_info = info.daily_report_arr[j];
            if (row_daily_info["a_date"] == opt_obj["select_date_str"]
              &&!strFunc.is_empty(row_daily_info["a_lecture_seq"])) {
              if (opt_obj.is_except_new) {
                if (!strFunc.is_empty(row_daily_info["a_seq"])) {
                  select_info_arr.push(row_daily_info);
                }
              } else {
                select_info_arr.push(row_daily_info);
              }
            }
          }
        }
      }
    }

    return select_info_arr;
  };

  const save_daily_report_by_stu_arr = (inData: any) => {
    let opt_obj = {
      report_data_arr: get_select_daily_report_arr({ is_except_new: false }),
      is_refresh_daily_study_title_arr:false,
      ...inData
    };
    //보고서 모으기
    let report_data_arr: any = opt_obj.report_data_arr;
    if (report_data_arr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }

    let w_report_form_json = {
      "data_arr": report_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/write', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 600);

          let tmp_change_info_arr=DailyDataFunc.get_stu_info_arr_of_update_daily_info({
            info_arr: infoArr,
            update_daily_report_arr: response.data["data"],
          });
          setInfoArr(tmp_change_info_arr);
          if(opt_obj.is_refresh_daily_study_title_arr){
            refresh_daily_study_title_arr_by_ajax({
              info_arr:tmp_change_info_arr,
              report_data_arr:report_data_arr,
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refresh_daily_study_title_arr_by_ajax=(inData:any)=>{
    let opt_obj={
      info_arr:infoArr,
      report_data_arr:[],
      ...inData,
    };
    let report_data_arr=opt_obj.report_data_arr;
    if(report_data_arr.length==0){
      return false;
    }
    let s_study_title_seq_arr=[];
    for(let i=0;i<report_data_arr.length;i++){
      if(!strFunc.is_empty(report_data_arr[i]["a_textbook_seq"])){
        s_study_title_seq_arr.push(report_data_arr[i]["a_textbook_seq"]);
      }
      if(!strFunc.is_empty(report_data_arr[i]["a_subtextbook_seq"])){
        s_study_title_seq_arr.push(report_data_arr[i]["a_subtextbook_seq"]);
      }
    }
    if(s_study_title_seq_arr.length==0){
      return false;
    }
    
    let tmp_form_json={
      "s_pri_arr":s_study_title_seq_arr,
      "s_addon_section":"1",
      "s_addon_study":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', tmp_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let tmp_change_info_arr=DailyDataFunc.get_stu_info_arr_of_update_study_title_arr({
            info_arr: opt_obj["info_arr"],
            study_title_arr: response.data["data"]["info_arr"],
          });
          setInfoArr(tmp_change_info_arr);
        } else {
          //alert(response.data["msg"]);
        }
      });
  };


  const delete_daily_report_by_stu_arr = () => {
    //보고서 모으기
    let report_data_arr: any = get_select_daily_report_arr({ is_except_new: true });
    if (report_data_arr.length == 0) {
      alert("삭제 할 데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let w_report_form_json = {
      "data_arr": report_data_arr,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/delete', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 1200);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const onClickPrint = () => {
    if (time_table_wrap_ref.current) {
      time_table_wrap_ref.current.style.marginTop = "0px";
      time_table_wrap_ref.current.style.transform = "scale(1)";
      set_is_print_mode(true);
      setTimeout(() => {
        expand_print_div_css();
        set_is_print_mode(false);
      }, 3000);
      setTimeout(() => { handlePrint(); }, 500);

    }
  };

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (!confirm(confirm_msg)) {
      return false;
    }
    let del_form_data = {
      "data_arr": dataArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const openSendSmsPopup = () => {
    let receive_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let u_info: any = infoArr[i];
      if (strFunc.str_in_array(u_info["a_seq"], check_row_val_arr) != -1) {
        let row_is_add_stu_phone = false;
        let row_is_add_parent_phone = false;
        if (sms_phone_check == "student") {
          row_is_add_stu_phone = true;
        } else if (sms_phone_check == "parent") {
          row_is_add_parent_phone = true;
        } else if (sms_phone_check == "both") {
          row_is_add_stu_phone = true;
          row_is_add_parent_phone = true;
        }
        if (row_is_add_stu_phone) {
          receive_arr.push({
            phone_num: u_info["a_user_phone"],
            user_name: u_info["a_user_name"],
            user_seq: u_info["a_seq"]
          });
        }
        if (row_is_add_parent_phone) {
          if (u_info.link_user_arr && u_info.link_user_arr[0] && u_info.link_user_arr[0]["par_info"]) {
            let tmp_line_len = u_info.link_user_arr.length;
            for (let j = 0; j < tmp_line_len; j++) {
              let par_info = u_info.link_user_arr[j]["par_info"];
              receive_arr.push({
                "user_seq": par_info.a_seq,
                "phone_num": strFunc.autoHypenPhone(par_info.a_user_phone),
                "user_name": par_info.a_user_name
              });
            }
          }
        }
      }
    }

    if (receive_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    setPopupData({
      ...popupData,
      isOpen: true,
      has_header: true,
      sort: "send_sms",
      title: "문자전송",
      receive_arr: receive_arr
    });
  };

  const openAttendPopup = (selected_row: [], attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    if (xColumnArr.select_arr.a_sort_detail) {
      for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
        let item = xColumnArr.select_arr.a_sort_detail[i];
        if (item.value == attend_sort_detail) {
          pop_title = item.text;
        }
      }
    }

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_attend",
      "title": pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      select_date: attend_date,
      user_info_arr: selected_row,
      has_header: true,
    });
  };

  const openAttendBySelect = (attend_sort: string, attend_sort_detail: string) => {
    let selected_rows: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let u_info: any = infoArr[i];
      if (strFunc.str_in_array(u_info["a_seq"], check_row_val_arr) != -1) {
        selected_rows.push(u_info);
      }
    }
    openAttendPopup(selected_rows, attend_sort, attend_sort_detail);
  };

  const open_write_td_data_popup = (inData: any) => {
    let opt_obj = {
      daily_report: {},
      td_sort: "textbook",//textbook,subtextbook
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "write_td",
      title: "학습기록",
      write_td_data: {
        daily_report: opt_obj["daily_report"],
        td_sort: opt_obj["td_sort"]
      },
      width: "700px",
      has_header: true,
    });
  };

  const openTodoDaySort = (inData: any) => {
    let opt_obj = {
      select_stu_name: "",
      select_stu_seq: "",
      select_date: listOpt.s_addon_todo_start_date,
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "todo_day_sort",
      title: "",
      width: "800px",
      has_header: true,
      ...opt_obj
    });
  };

  const open_stu_layer_popup = (inData: any) => {
    let opt_obj = {
      stu_info: null,
      is_scroll: false,
      ...inData
    };
    let stu_info = opt_obj.stu_info;
    if (strFunc.is_empty(stu_info)) {
      alert("학생정보가 없습니다.");
      return false;
    }
    let select_idx = 0;
    let stu_info_arr: any = [stu_info];

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "원생관리",
      "sort": "stu_view",
      write_page_data: {
        is_show: true,
        is_update: true,
        is_view_mode: true,
        is_view_basic: false,
        select_idx: select_idx,
        info_arr: stu_info_arr,
        default_write_info: {},
        tab_id: "monthly_prescribe",
        is_first_tab_scroll: opt_obj["is_scroll"],
        counsel_s_select_date: "",
      },
      width: "98%",
      height: "99%",
      y: 0,
      has_header: false,
    });
  };

  const open_find_study_title_popup = (inData: any) => {
    let opt_obj = {
      row_num: "",
      key_str: "",
      par_info: null,//daily_info
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "find_study_title",
      title: "학습내용 찾기",
      row_num: opt_obj.row_num,
      key_str: opt_obj.key_str,
      par_info: opt_obj.par_info,
      has_header: true,
      width: "700px",
      height: "80%",
      ...opt_obj
    });
  };

  const callback_study_title = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: "",
      key: "",
      par_info: null,
      ...inData
    };
    if (opt_obj["info_arr"].length == 0) {
      return false;
    }
    if (opt_obj.key == "a_textbook") {
      opt_obj["par_info"]["a_textbook"] = opt_obj["info_arr"][0]["a_title"];
      opt_obj["par_info"]["a_textbook_seq"] = opt_obj["info_arr"][0]["a_seq"];
    } else if (opt_obj.key == "a_subtextbook") {
      opt_obj["par_info"]["a_subtextbook"] = opt_obj["info_arr"][0]["a_title"];
      opt_obj["par_info"]["a_subtextbook_seq"] = opt_obj["info_arr"][0]["a_seq"];
    }

    save_daily_report_by_stu_arr({
      report_data_arr: [opt_obj["par_info"]],
      is_refresh_daily_study_title_arr:true,
    });
  };

  const callback_go_remove_select_title = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: "",
      key: "",
      par_info: null,
      ...inData
    };
    if (!confirm("교재선택을 해제하시겠습니까?")) {
      return false;
    }
    if (opt_obj.key == "a_textbook") {
      opt_obj["par_info"]["a_textbook"] = "";
      opt_obj["par_info"]["a_textbook_seq"] = "";
    } else if (opt_obj.key == "a_subtextbook") {
      opt_obj["par_info"]["a_subtextbook"] = "";
      opt_obj["par_info"]["a_subtextbook_seq"] = "";
    }

    save_daily_report_by_stu_arr({
      report_data_arr: [opt_obj["par_info"]],
      is_refresh_daily_study_title_arr:true,
    });
  };

  return (
    <CompLayout isConTitle={false} >
      <div className="con_wrap">
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          xColumnArr={xColumnArr}
          list={list}
        >
          <SearchReportArea
            listOpt={listOpt}
            list={list}
            is_show_etc_group={is_show_etc_group}
            set_is_show_etc_group={(inData: any) => { set_is_show_etc_group(inData); set_refresh(!refresh) }}
            show_group_time={show_group_time}
            set_group_time={set_group_time}
            able_time_arr_ref={able_time_arr_ref}
          ></SearchReportArea>
        </SearchArea>
        <div className="my-1">
          <ListTabArea
            list_tab={list_tab}
            set_list_tab={(inData: any) => {
              set_list_tab(inData);
              strFunc.set_storage("daily_report_list_tab", inData);
            }}
          ></ListTabArea>
        </div>
        <SearchSubArea
          listOpt={listOpt}
          openAttendBySelect={openAttendBySelect}
          list={list}
          navigate={navigate}
          on_check_all={on_check_all}
          onClickPrint={onClickPrint}
          sms_phone_check={sms_phone_check}
          set_sms_phone_check={set_sms_phone_check}
          openSendSmsPopup={openSendSmsPopup}
          save_daily_report_by_stu_arr={save_daily_report_by_stu_arr}
          delete_daily_report_by_stu_arr={delete_daily_report_by_stu_arr}
        ></SearchSubArea>
        <div className="mt-2" ref={time_table_wrap_ref}>
          {list_tab == "week" &&
            <CardListArea
              tab_id={tab_id}
              info_arr={infoArr}
              set_info_arr={(inData: any) => {
                setInfoArr(inData);
                set_refresh(!refresh);
              }}
              select_date={listOpt.s_addon_daily_report_start_date}
              list={list}
              check_row_val_arr={check_row_val_arr}
              set_check_row_val_arr={(inData: any) => {
                set_check_row_val_arr(inData);
                set_refresh(!refresh);
              }}
              is_print_mode={is_print_mode}
              is_show_etc_group={is_show_etc_group}
              show_group_time={show_group_time}
              set_group_time={set_group_time}
              able_time_arr_ref={able_time_arr_ref}
              xColumnArr={xColumnArr}
              openAttendPopup={openAttendPopup}
              deleteAttend={deleteAttend}
              open_write_td_data_popup={open_write_td_data_popup}
              openTodoDaySort={openTodoDaySort}
              open_stu_layer_popup={open_stu_layer_popup}
            ></CardListArea>
          }
          {list_tab == "day" &&
            <DailyListArea
              tab_id={tab_id}
              info_arr={infoArr}
              set_info_arr={(inData: any) => {
                setInfoArr(inData);
                set_refresh(!refresh);
              }}
              select_date={listOpt.s_addon_daily_report_start_date}
              list={list}
              check_row_val_arr={check_row_val_arr}
              set_check_row_val_arr={(inData: any) => {
                set_check_row_val_arr(inData);
                set_refresh(!refresh);
              }}
              is_print_mode={is_print_mode}
              is_show_etc_group={is_show_etc_group}
              show_group_time={show_group_time}
              set_group_time={set_group_time}
              able_time_arr_ref={able_time_arr_ref}
              xColumnArr={xColumnArr}
              save_daily_report_by_stu_arr={save_daily_report_by_stu_arr}
              openAttendPopup={openAttendPopup}
              deleteAttend={deleteAttend}
              open_write_td_data_popup={open_write_td_data_popup}
              openTodoDaySort={openTodoDaySort}
              open_stu_layer_popup={open_stu_layer_popup}
              open_find_study_title_popup={open_find_study_title_popup}
            ></DailyListArea>
          }
        </div>
        <div className="mt-2">
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
            is_view_num_per_page={true}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={popupData.y} has_header={popupData.has_header}  >
          {popupData.sort == "send_sms" &&
            <SendSMSPopup
              receive_arr={popupData.receive_arr}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackRefresh={list}
            ></SendSMSPopup>
          }
          {popupData.sort === "add_attend" &&
            <AddAttendPopup attend_sort={popupData.attend_sort}
              attend_sort_detail={popupData.attend_sort_detail}
              attend_date={popupData.select_date}
              userInfoArr={popupData.user_info_arr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
          }
          {popupData.sort === "write_td" &&
            <WriteTdAndStudyPopup
              list={list}
              daily_report={popupData.write_td_data.daily_report}
              td_sort={popupData.write_td_data.td_sort}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteTdAndStudyPopup>
          }
          {popupData.sort == "todo_day_sort" &&
            <ViewTodoDaySortPopup
              list_opt={{
                "s_stu_user_name": popupData.select_stu_name,
                "s_stu_user_seq": popupData.select_stu_seq,
                "s_start_date": popupData.select_date,
                "s_end_date": popupData.select_date,
              }}
              list={list}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewTodoDaySortPopup>
          }
          {popupData.sort === "stu_view" &&
            <ViewStuLayerPopup
              write_page_data={popupData.write_page_data}
              callback={(inData: any) => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuLayerPopup>
          }
          {popupData.sort === "find_study_title" &&
            <FindStudyTitlePopup
              row_num={popupData.row_num}
              key_str={popupData.key_str}
              par_info={popupData.par_info}
              is_remove_select_title_btn={true}
              go_remove_select_title={callback_go_remove_select_title}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={callback_study_title}
            ></FindStudyTitlePopup>
          }
        </LayerPopup>
      }
      {loading_data.is_display &&
        <LoadingComponent text={loading_data.text}></LoadingComponent>
      }
    </CompLayout>
  );
};
export default DailyReportCardListPage;